<div class="forgot-password-screen">
  <div class="right-part"></div>
  <div class="d-flex flex-column justify-content-center h-100">
    <div class="col-lg-5 col-12 m-auto d-table">
      <div class="px-3">
        <div class="mb-3 mb-md-5 mt-3 mt-lg-0">
          <img src="../../../assets/img/login-logo.svg" class="m-auto d-table logo" alt="">
        </div>
        <h4 *ngIf="!isLinkValid">{{LabelConstants.ResetPassword}}</h4>
        <form [formGroup]="passwordForm" (ngSubmit)="submitResetPassForm()">
          <div>
            <div class="form-group mb-2 mb-md-4" *ngIf="!isLinkValid">
              <label>{{LabelConstants.Password}}<span class="text-danger">*</span></label>
              <div class="position-relative mb-1">
                <input oninput="this.value = this.value.replace(/^\s+/g, '')" [type]="showNewPassword ? 'text' : 'password'" class="form-control" formControlName="NewPassword" placeholder="New password" maxlength="99">
                <i class="fa-regular fa-eye visibility cursor"  *ngIf="!showNewPassword" (click)="togglePasswordVisibility('showNewPassword')"></i>
                <i class="fa-regular fa-eye-slash visibility cursor"  *ngIf="showNewPassword" (click)="togglePasswordVisibility('showNewPassword')"></i>
              </div>
              <span class="text-danger" *ngIf="submitted && ChangePassControls['NewPassword'].errors?.['required']">{{ErrorConstants.NewPassRequired}}</span>
              <span class="text-danger" *ngIf="submitted && ChangePassControls['NewPassword'].errors?.['pattern']">{{ErrorConstants.NewPassInvalid}}</span>
            </div>
            <div class="form-group mb-2 mb-md-4"*ngIf="!isLinkValid">
              <label>{{LabelConstants.ConfirmPassword}}<span class="text-danger">*</span></label>
              <div class="position-relative mb-1">
                <input oninput="this.value = this.value.replace(/^\s+/g, '')" [type]="showConfirmPassword ? 'text' : 'password'"class="form-control" formControlName="ConfirmNewPassword" placeholder="Confirm password" maxlength="99">
                <i class="fa-regular fa-eye visibility cursor"  *ngIf="!showConfirmPassword" (click)="togglePasswordVisibility('showConfirmPassword')"></i>
                <i class="fa-regular fa-eye-slash visibility cursor"  *ngIf="showConfirmPassword" (click)="togglePasswordVisibility('showConfirmPassword')"></i>
              </div>
              <span class="text-danger" *ngIf="submitted && ChangePassControls['ConfirmNewPassword'].errors?.['required']">{{ErrorConstants.ConfPassRequired}}</span>
                   <span class="text-danger" *ngIf="submitted && passwordForm.hasError('mismatch') && passwordForm.get('ConfirmNewPassword').touched">
                   {{ErrorConstants.PassNotMatch}}
                   </span>
            </div>
            <div class="text-center"  *ngIf="isLinkValid">
              <p>{{LabelConstants.expiredLink}}</p>
             </div>
          </div>
          <div class="mt-3">
            <button *ngIf="!isLinkValid" class="btn login-btn mt-3 mb-2 mb-md-3" type="submit">{{LabelConstants.ResetPassword}} <i
                class="fa-solid fa-arrow-right ms-1"></i></button>
            <div class="form-group d-flex align-items-center justify-content-center">
              <a href="#" class="mt-4 mb-3 d-block text-pink text-center cursor">{{LabelConstants.GoBack}}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>