import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private storageService: StorageService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private apiUrl: ApiUrlHelper,
  ){}

    // Common Properties
    RegistrationForm: FormGroup= this.formBuilder.group({});
    submitted = false;

    ngOnInit(): void {
      this.OnInitRegisterForm();
    }

  // RegisterUser Form
    OnInitRegisterForm(){
      this.RegistrationForm = this.formBuilder.group({
        FirstName: ['',[Validators.required]],
        LastName: ['',[Validators.required]],
        EmailAddress: ['', [Validators.required , Validators.email,Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        Password:['',[Validators.required]],
        ConfirmPassword: ['',[Validators.required]]
      }, {
        validator: this.passwordMatchValidator 
      });
    }
    
    get GetRegistrationControls(){
      return this.RegistrationForm.controls;
    }

  // For Matching Password And Confirm Pass
  passwordMatchValidator(form: FormGroup) {
    const passwordControl = form.get('Password');
    const confirmPasswordControl = form.get('ConfirmPassword');

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  }

  hasPasswordMismatchError() {
    return this.RegistrationForm.get('ConfirmPassword').hasError('passwordMismatch');
  }

  // Register User
  RegisterUser(){
    this.submitted = true;
    if(this.RegistrationForm.invalid ){
      return;
    }
    if(this.RegistrationForm.valid){
      this.spinner.show();
      let RegisterObj ={
            RoleManagementId: 0,
            Email: this.RegistrationForm.value.EmailAddress?.trim(),
            Firstname: this.RegistrationForm.value.FirstName?.trim(),
            Lastname: this.RegistrationForm.value.LastName?.trim(),
            Password: this.RegistrationForm.value.Password?.trim(),
            UserId: 0,
            CreatedBy: 0,
            PasswordSalt: '',
            UserImage: '',
            UserMobileNumber: '',
            UserAddress: '',
            UserCountryId: 0,
            UserStateId: 0,
            UserCityId: 0,
            UserPostalCode: ''
      };
      let apiUrl = this.apiUrl.apiUrl.Register.RegisterUser;
      this.commonService.doPost(apiUrl,RegisterObj).pipe().subscribe({
        next:(response)=>{
          if(response.success){
            this.toster.success(response.message);
            this.spinner.hide();
            this.router.navigate(['/login']);
          }
          else{
            this.toster.error(response.message);
            this.spinner.hide();
          }
        },
        error: (err) => {
          this.spinner.hide();
          console.log(err);
        }
      });
    }
  }
}