export enum USER_TYPE {
    ADMIN = 1,
    CUSTOMER = 2,
  }

export enum URL_TYPE {
    FACEBOOK = "Facebook",
    INSTAGRAM = "Instagram",
    TWITTER = "Twitter X",
    YOUTUBE = "Youtube",
    EMAIL = "ContactUs",
    TAX = "TaxPercentage",
    email = "Email"
}

export enum Sorting_Headers {
  EVENTNAME = "EventName",
  AMOUNT = "Amount",
  SCHEDULE = "schedule",
  CLASSNAME = "ClassName",
  HOSTEDBY = "Hosted By",
  DATE = "Date",
  TIME = "Time"
}

export enum Suffix {
  ST = "st",
  ND = "nd",
  RD = "rd",
  TH = "th"
}

export enum QUESTION_TYPE {
  TEXT = 1,
  RADIO = 2,
  TEXTAREA = 3,
  CHECKBOX = 4
}

export enum CLASS_TYPE {
  SINGLE_SESSION_CLASS = 1,
  MULTIPLE_SESSION_CLASS = 2
}

export enum PASS_TYPE {
  FREE = 5,
  NO_OF_PASS = 6,
  UNLIMITED = 7
}

export enum REPORT_TYPE {
  PENDING = 1,
  APPROVE = 3,
  REJECT = 2,
  APPROVE_WITH_REFUND = 4
}


export enum REPORT_STATUS_TYPE {
  APPROVE = 1,
  REJECT = 2,
  VIEW = 3
}

export enum ACTIVITY_TYPE {
  SESSIONS= 0,
  EVENTS = 1,
  REPORT = 2,
  REFUND = 3
}

export enum REPORT_STATUS {
  PENDING = 1,
  REJECTED = 2,
  APPROVED = 3,
  APPROVEDWITHREFUND = 4
}

export enum DISCOUNT_TYPE {
  FLAT = 1,
  PERCENTAGE = 2
} 