import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ResetPassPageComponent } from './pages/reset-pass/reset-pass-page.component';
import { UnderMainatanceComponent } from './pages/under-mainatance/under-mainatance.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: {
      title: 'User Registration'
    }
  },
  {
    path: 'resetpass/:Id/:time',
    component: ResetPassPageComponent,
    data: {
      title: 'Reset Password'
    }
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layout/main/main.module').then((m) => m.MainModule)

      }
    ]
  },
  {
    path: 'under-maintenance',
    component: UnderMainatanceComponent,
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      )
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
