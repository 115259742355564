import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ResetPassErrorConstants } from 'src/app/constants/ErrorMessages';
import { ResetPassLabelConstants } from 'src/app/constants/LabelConstants';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';

@Component({
  selector: 'app-reset-pass-page',
  templateUrl: './reset-pass-page.component.html',
  styleUrls: ['./reset-pass-page.component.scss'],
})
export class ResetPassPageComponent implements OnInit {
  // Common Properties
  passwordForm: FormGroup;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  submitted: boolean = false;
  EncryptedUserId: any;
  ErrorConstants: any;
  LabelConstants: any;
  isLinkValid: boolean = false;
  currentUrlTime:any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private api: ApiUrlHelper,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%^&*])[A-Za-z\d!@#\$%^&*]{8,16}$/;
    this.passwordForm = this.fb.group(
      {
        NewPassword: [
          '',
          [Validators.required, Validators.pattern(passwordPattern)],
        ],
        ConfirmNewPassword: ['', [Validators.required]],
      },
      {
        validator: this.passwordMatchValidator,
      }
    );
  }

  ngOnInit(): void {
    this.sharedService.setData("Reset Password");
    this.EncryptedUserId = this.route.snapshot.paramMap.get('Id');
    this.currentUrlTime = this.route.snapshot.paramMap.get('time');
    this.ErrorConstants = ResetPassErrorConstants;
    this.LabelConstants = ResetPassLabelConstants;
    this.isResetPassValid();
  }

  get ChangePassControls() {
    return this.passwordForm.controls;
  }

  togglePasswordVisibility(fieldName: string) {
    this[fieldName] = !this[fieldName];
  }

  passwordMatchValidator(formGroup: FormGroup) {
    return formGroup.get('NewPassword').value ===
      formGroup.get('ConfirmNewPassword').value
      ? null
      : { mismatch: true };
  }

  submitResetPassForm() {
    this.submitted = true;
    if (this.passwordForm.valid) {
      this.submitted = false;
      this.spinner.show();
      let api = this.api.apiUrl.ForgotPass.ResetPassword;
      let PasswordChangeRequestModel = {
        EncryptedUserId: this.EncryptedUserId,
        Password: this.passwordForm.value.ConfirmNewPassword?.trim(),
        IsButtonClicked: true,
        DateTime: this.currentUrlTime
      };
      this.commonService
        .doPost(api, PasswordChangeRequestModel)
        .pipe()
        .subscribe({
          next: (response) => {
            if (response.success) {
              this.toastr.success(response.message);
              this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              this.toastr.error(response.message);
              this.spinner.hide();
              this.router.navigate(['/forgot-password']);
            }
          },
          error: (err) => {
            this.spinner.hide();
            console.log(err);
          }
        });
    }
  }

  isResetPassValid() {
    this.spinner.show();
    let api = this.api.apiUrl.ForgotPass.ResetPassword;
    let PasswordChangeRequestModel = {
      EncryptedUserId: this.EncryptedUserId,
      Password: '',
      IsButtonClicked: false,
      DateTime: this.currentUrlTime
    }
    this.commonService.doPost(api, PasswordChangeRequestModel).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.spinner.hide();
        }
        else {
          this.isLinkValid = true;
          this.spinner.hide();
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    });
  }
}