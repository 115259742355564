<aside id="sidebar" class="sidebar break-point-lg has-bg-image">
  <a id="btn-collapse" class="sidebar-collapser"><i class="fa-solid fa-arrow-left"></i></a>
  <div class="sidebar-layout">
    <div class="sidebar-header">
      <div class="small-logo cursor-pointer btn-toggle-dashboard"  routerLink="/dashboard">
        <img src="../../../assets/img/small-logo.svg" alt="">
     </div>
      <div class="pro-sidebar-logo cursor-pointer btn-toggle-dashboard" routerLink="/dashboard">
         <img src="../../../assets/img/sidebar-logo.svg" alt="">
      </div>
    </div>
    <div class="sidebar-content">
      <nav class="menu open-current-submenu" id="myNav">
        <ul>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/dashboard" [routerLinkActiveOptions]="{exact: true}" >
              <span class="menu-icon">
                 <img src="../../../assets/icons/dashboard.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.dashboard}}</span>
            </a>
          </li>
          <li class="menu-item"  *ngIf="isAdmin" >
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/user">
              <span class="menu-icon">
                <img src="../../../assets/icons/user-management.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.userManagement}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="!isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active"  routerLink="/yoga-session">
              <span class="menu-icon">
                <img src="../../../assets/icons/session.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.sessions}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="!isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active"  routerLink="/yoga-event">
              <span class="menu-icon">
                <img src="../../../assets/icons/event.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.events}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active"  routerLink="/class">
              <span class="menu-icon">
                <img src="../../../assets/icons/session.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.classes}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/customer">
              <span class="menu-icon">
                <img src="../../../assets/icons/user.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.customers}}</span>
            </a>
          </li>
          <li class="menu-item" >
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" *ngIf="isAdmin" routerLink="/event" >
              <span class="menu-icon">
                <img src="../../../assets/icons/event.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.events}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/order">
              <span class="menu-icon">
                <img src="../../../assets/icons/orders.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.orders}}</span>
            </a>
          </li>
          <li class="menu-item"*ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/formbuilder" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/form-builder.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.formBuilder}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/passes">
              <span class="menu-icon">
                <img src="../../../assets/icons/payment.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.passes}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active"   routerLink="/coupon-code">
              <span class="menu-icon">
                <img src="../../../assets/icons/coupon.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.couponCode}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/reward-coupon">
              <span class="menu-icon">
                <img src="../../../assets/icons/rewards.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.rewardsCoupon}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/report" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/report.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.reports}}</span>
            </a>
          </li>
          <li class="menu-item" *ngIf="isAdmin">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/setting" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/settings.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.settings}}</span>
            </a>
          </li>
          <li class="menu-item logout-menu">
            <a href="javascript:void(0)" (click)="LogOut('Logout')" >
              <span class="menu-icon">
                <img src="../../../assets/icons/logout.svg" alt="">
              </span>
              <span class="menu-title text-white">{{LabelConstant.logout}}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</aside>
<div id="overlay" class="overlay"></div>
