import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { StorageService } from 'src/app/services/storage/storage.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { MatDialog } from '@angular/material/dialog';
import { USER_TYPE } from 'src/app/common/Constants/enums';
import { SidebarLabelConstant } from 'src/app/constants/LabelConstants';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})

export class MenuSidebarComponent implements OnInit {
  isAdmin : boolean = false;
  UserId:any;
  LabelConstant:any;

  constructor(private router: Router,
    private storageService: StorageService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private apiUrl: ApiUrlHelper,
    private dialog: MatDialog,) {}

  ngOnInit() {
    this.UserId = this.storageService.getValue('userId');
    let role = this.storageService.getValue('userRole');
    if(role == USER_TYPE.ADMIN) {
      this.isAdmin = true;
    }
    else if ( role == USER_TYPE.CUSTOMER){
      this.isAdmin = false;
    }
    else {
      this.isAdmin = false;
      this.router.navigate(['/login']);
    }
    this.LabelConstant = SidebarLabelConstant;
  }

  LogOut(action:any){
    let apiUrl = this.apiUrl.apiUrl.Login.logout;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width:'460px',
      data: { action, heading : "Logout", isSession : false },
      disableClose: true,
      autoFocus: false  
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.spinner.show();
        this.commonService.doPost(apiUrl,this.UserId).pipe().subscribe({
          next:(response)=>{
            if(response.success){
              this.router.navigate(['/login']);
              localStorage.clear();
              this.spinner.hide()
            }
            else{
              this.toster.error(response.message);
              this.spinner.hide()
            }
          },
          error: (err) => {
            this.spinner.hide();
            console.log(err);
          }
        })
      }
    });    
  }
}
